import React from "react";
import "./navbar.css";
import { FaInstagram, FaLinkedin } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
 import { Link } from "react-router-dom";


import DigiHumansLogo from '../../assets/DigiHumansLogo.png'
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import { useState } from "react";

//app.logo.com- logo creation site



    const Social = () => (
        <>
            <a href="https://www.instagram.com/digihumans.ai/" target="_blank"><FaInstagram color="#fff" size={27} /></a>
            <a href="https://www.linkedin.com/company/digihumans-ai/" target="_blank"><FaLinkedin color="#fff" size={27} /></a>
            <a href="https://twitter.com/Digihumansai" target="_blank"><FaXTwitter color="white" size={26}/></a>

        </>
    )


export default function Navbar({scrollToSection}){

    const [toggleMenu, setToggleMenu] = useState(false)


    return (
        <div className="navbar" id="navbar">
            <div className="navbar-container">
                <Link to='/ '>
                    <div className="navbar-logo">
                        <img src={DigiHumansLogo} alt="digihumans logo"/>
                        <div className="navbar-name">DIGIHUMANS.AI</div>
                    </div>
                </Link>

                <div className="navbar-links-container">
                    
                            <p onClick={() => scrollToSection(1)}><a >Use Cases</a></p>
                            <p onClick={() => scrollToSection(6)} ><a>Products</a></p>
                            <p onClick={() => scrollToSection(10)}><a>Contact</a></p>
                            
                </div>

                <div className="navbar-social-container">
                    <Social />
                </div>

                <div className="navbar-menu-mobile">
                    {
                        toggleMenu ? <RiCloseLine  color="#fff" size={27} onClick={() => setToggleMenu(false)} /> : 
                        <RiMenu3Line  color="#fff" size={27} onClick={() => setToggleMenu(true)} /> 
                    }

                    {toggleMenu && (
                        <div className="navbar-menu-mobile-container slide-top">
                        <div className="navbar-menu-mobile-links">
                        <p onClick={() => scrollToSection(1)}><a >Usecases</a></p>
                            <p onClick={() => scrollToSection(6)} ><a>Products</a></p>
                            <p onClick={() => scrollToSection(11)}><a>Contact</a></p>
                        </div>
                        <div className="navbar-social-container-mobile">
                            <Social />
                        </div>
                        </div>
                        
                    )}
                </div>
            </div>
        </div>
    );

}