import './App.css';
import { Navbar } from './components';
import { FaArrowUp } from "react-icons/fa";
import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Main, Soulmachines } from './pages';




function App() {
  let [toTopVisible, setToTopVisible] = useState(false)
  const [scrollPercentage, setScrollPercentage] = useState(0);


  useEffect(() => {
    // Check if scrollRestoration is supported
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'; // Prevent automatic scroll restoration.
      window.scrollTo(0, 0); // Scroll to the top.
    }
  }, []);


  const handleScroll = () => {
    const totalScroll = document.documentElement.scrollTop;
    const windowHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrollPercent = totalScroll / windowHeight;
    
    setScrollPercentage(scrollPercent);
    setToTopVisible(totalScroll > 800);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);



const scrollToSection = (multiplier) => {
  
    const viewportHeight = window.innerHeight;
    const scrollToPosition = viewportHeight * multiplier;
    window.scrollTo({
      top: scrollToPosition,
      behavior: 'smooth'
    });
  
};

  return (

    <BrowserRouter>

    <div>

       {
        toTopVisible && 
        <div className="back-to-top-progress" style={{opacity:'0.8', background: `conic-gradient(white ${scrollPercentage * 360}deg, transparent ${scrollPercentage * 360}deg)`}}>
            <button className='back-to-top-bttn' onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
              <FaArrowUp size={24} style={{ color: 'white'}} />
            </button>
          </div>
      }
          <Navbar  scrollToSection={scrollToSection}  />

          <Routes>
          <Route path='/' element={<Main  scrollToSection={scrollToSection}/>} /> 
              <Route path='/secret/test/soulmachines' element={<Soulmachines />} /> 

          </Routes>
          
      
    </div>
    </BrowserRouter>
  );
}

export default App;

