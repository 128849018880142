import React from 'react'
import './footer.css'


export const Footer = () => {
  return (
    <div id='footer'>
        <div className="footer-container">
            <div className="footer-content-container">
                Copyright &copy; 2024 DigiHumans.ai All Rights Reserved     
            </div>
        </div>
    </div>
  )
}
