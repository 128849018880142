import React from 'react'
import './contact.css'
import { FaInstagram, FaLinkedin } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import { useState } from 'react';
import { Footer } from '../footer/Footer';
import emailImage from '../../assets/email-image.png'

const GoogleForm = () => {
    return (

      <div style={{width:'100%', background : 'white', textAlign : 'left'}}>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfaQ87uXiX9IGFCEfgHbB4stcX89JI8hx83GgDgyHe0ciV7Rg/viewform?embedded=true" width="100%" height="500" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
</div>
    );
  };



  


export const Contact = () => {


   

    const [formState, setFormState] = useState({
        name: "",
        email: "",
        message: "",
        honeypot: "",

    })

    const [errorsState, setErrorsState] =useState({
        name: false,
        email: false,
        message: false,
        honeypot: false,
    })

    const [hasErrors, setHasErrors] = useState(false)
    const [submitMessage, setSubmitMessage] = useState("")
    const [formSubmitted, setFormSubmitted] = useState(false)

    

    const handleNameChange = (event) => {
        const { value } = event.target;
        const lettersOnlyRegex =/^[a-zA-Z ]+$/;

    
    
        setFormState(prevValues => ({...prevValues, 'name': value}));
    
        if (lettersOnlyRegex.test(value)) {
            setErrorsState(prevErrors => ({...prevErrors, 'name': false}));
            
        } else {
            setErrorsState(prevErrors => ({...prevErrors, 'name': true}));
        }
    };

    const handleEmailChange = (event) => {
        const { value } = event.target;
        // Regular expression to validate email addresses
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    
    
        setFormState(prevValues => ({...prevValues, 'email': value}));
    
        if (emailRegex.test(value)) {
            setErrorsState(prevErrors => ({...prevErrors, 'email': false}));
            
        } else {
            setErrorsState(prevErrors => ({...prevErrors, 'email': true}));
        }
    };

    const handleMessageChange = (event) => {
        const { value } = event.target;
        const sanitizedValue = value.replace(/\n/g, '');
        const messageRegex = /^.{75,}$/;
    
    
        setFormState(prevValues => ({...prevValues, 'message': value}));
    
        if (messageRegex.test(sanitizedValue)) {
            setErrorsState(prevErrors => ({...prevErrors, 'message': false}));
            
        } else {
            setErrorsState(prevErrors => ({...prevErrors, 'message': true}));
        }
    };

    const handleHoneypotChange = (event) => {
        if (formState.honeypot) {
            setErrorsState(prevErrors => ({...prevErrors, 'honeypot': true}));
            setSubmitMessage('Invalid submission detected.');
            return;
          }


    }

    const handleSubmit = async () => {
        // Check if all values are non-empty
        const allFieldsFilled = Object.entries(formState)
                                    .filter(([key]) => key !== 'honeypot')
                                    .every(([, value]) => value.trim() !== "");
        const allErrorsFalse = Object.values(errorsState).every(value => value === false);

    

        if (allFieldsFilled && allErrorsFalse) {

            try {
                const response = await fetch('http://localhost:8000/contact-form/send-email', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formState),
                    });
                
    
                
            } catch (error) {
                console.error("Error submitting form:", error);
                setSubmitMessage("Error submitting the form. Try again later.")
                setHasErrors(true);
                return
            }

            setHasErrors(false)
            console.log("submitted")
            setSubmitMessage("Form has been submitted!")
            setFormSubmitted(true)
        } else {
            console.log("hey something is wrong!")

            setSubmitMessage("Please enter valid details in all fields")
            setHasErrors(true)
            
        }
    };
    
    
    



    

  return (
    <div id='contact' >
        <div className="contact-container">

            <div className="contact-upper-container">
                <div className="contact-header">Contact</div>
            </div>

            <div className='contact-lower-container'>
            <div className="contact-left-container">
                <div className="contact-form" >
                    <label htmlFor="name" className='input-label'>Name<span style={{'color' : 'red'}}>*</span></label>
                    <input value={formState.name} onChange={handleNameChange}  className='input-tag' type="text" name="name" id="name" placeholder='enter your full name' required/>
                    {errorsState.name && <p className='error-message' >Name must include only letters.</p>}

                    <label htmlFor="email" className='input-label'>Professional Email<span style={{'color' : 'red'}}>*</span></label>
                    <input value={formState.email} onChange={handleEmailChange} className='input-tag' type="email" name="email" id="email" placeholder='enter a valid professional email' required/>
                    {errorsState.email && <p className='error-message' >enter a valid email like abc@xyz.com</p>}

                    <label htmlFor="message" className='input-label'>Message<span style={{'color' : 'red'}}>*</span></label>
                    <textarea value={formState.message} onChange={handleMessageChange} className='input-tag' name="message" id="message" cols="40" rows="6" placeholder='enter your message...' required />
                    {errorsState.message && <p className='error-message' >Message must have atleast 75 characters</p>}

                    {/* Honeypot field */}
                    <div style={{ display: 'none' }}>
                        <label htmlFor="honeypot">Do not fill this field</label>
                        <input value={formState.honeypot} onChange={handleHoneypotChange} type="text" name="honeypot" id="honeypot" />
                    </div>

                    
                    <button className='submit-button' onClick={handleSubmit} type='submit'>Submit</button>
                    {hasErrors && <p className='error-message' style={{'marginTop' : '0.25rem', 'textAlign' : 'center'}} >{submitMessage}</p>}
                    {formSubmitted && <p className='submitted-message' style={{'marginTop' : '0.25rem', 'textAlign' : 'center', 'color' : 'green'}} >{submitMessage}</p>} 

  </div>
  {/* <GoogleForm/> */}
  
 
            </div>
            <div className='seperator' >
                or
            </div>
            <div className="contact-right-container">
                <div className="contact-links">

                    <div className='contact-links-email'>
                        <div className='contact-links-email-header'>
                        Email us at: 
                        </div>
                       <div className="contact-link">
                       <div className='social-link-label'>
                            <img src={emailImage} alt="" />
                        </div></div>
                    </div>

                    <div className='contact-links-social'>
                        <div className='contact-links-social-header'>
                        Follow and Message us on: 
                        </div>
                        <a href="https://www.instagram.com/digihumans.ai/" target="_blank"> <div className="contact-link"> <FaInstagram color="#fff" size={30} /><div className='social-link-label'>Instagram</div></div></a>
                        <a href="https://www.linkedin.com/company/digihumans-ai/" target='_blank'> <div className="contact-link"><FaLinkedin color="#fff" size={30} /><div className='social-link-label'>Linkedin</div></div></a>
                        <a href="https://twitter.com/Digihumansai" target='_blank'> <div className="contact-link"><FaXTwitter color="white" size={29}/><div className='social-link-label'>Twitter</div></div></a>
                    </div> 

                   
                </div>
                
            </div>
        </div>
        </div>
        <Footer />
        
    </div>
  )
}
